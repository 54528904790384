import { useContext } from 'react';
import { LocationsContext } from 'contexts/locations';

export const useUserContext = () => {
  const context = useContext(LocationsContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a LocationsContextProvider');
  }

  return context;
};

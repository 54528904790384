import React from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { getAddressInfoFromComponents } from 'utils/utils';

export default function LocationField({ value, onChange, placeholder = 'Address' }) {
  const apiKey = process.env.REACT_APP_PLACES_API_KEY;
  const restrictions = {
    componentRestrictions: {
      country: ['us'],
    },
  };

  const handleLocationChange = async location => {
    if (location) {
      const geocodedLocation = await geocodeByAddress(location.label);
      const coordinates = await getLatLng(geocodedLocation[0]);

      const { state, city, zipcode } = getAddressInfoFromComponents(
        geocodedLocation[0].address_components
      );

      onChange({
        state,
        city,
        zipcode,
        line1: location.value.structured_formatting.main_text || '',
        latitude: coordinates.lat || '',
        longitude: coordinates.lng || '',
        formatted_address: geocodedLocation[0].formatted_address || '',
      });
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={apiKey}
      selectProps={{
        placeholder: placeholder,
        blurInputOnSelect: true,
        isClearable: true,
        defaultInputValue: '',
        onChange: handleLocationChange,
      }}
      autocompletionRequest={restrictions}
    />
  );
}

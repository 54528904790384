import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const palette = {
  text: {
    primary: '#28285C',
    secondary: '#6E7596',
  },
  primary: {
    main: '#F75010',
    dark: '#AC380B',
    light: '#FFF3EA',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#6E7596',
    light: '#9A9EB0',
    contrastText: '#FFFFFF',
  },
  accent: {
    main: '#28285C',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#40C833',
    light: '#EAFFE7',
    contrastText: '#1D1D1D',
  },
  warning: {
    main: '#FF9100',
    light: '#FFF3D6',
    contrastText: '#1D1D1D',
  },
  background: {
    default: '#E5E5E5',
  },
};

export const muiTheme = createTheme();

export const defaultTheme = createTheme(muiTheme, {
  palette,
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 800,
      fontSize: 32,
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 20,
    },
    h3: {
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 14,
    },
    subtitle3: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 700,
    },
    body3: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 700,
    },
    caption2: {
      fontFamily: 'Montserrat',
      fontSize: 12,
    },
    strong: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 28,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
          fontWeight: 700,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: {
            color: 'success',
          },
          style: {
            backgroundColor: palette.success.light,
            color: palette.success.main,
          },
        },
        {
          props: {
            color: 'warning',
          },
          style: {
            backgroundColor: palette.warning.light,
            color: palette.warning.main,
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: '0px 10px 30px rgba(6, 10, 47, 0.05)',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          strong: 'strong',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#F8F8FA',

          '& th': {
            color: '#80859F',
            fontSize: 12,
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& tr:last-child td': {
            border: '0px',
          },
        },
      },
    },
  },
});

export const ThemeProvider = ({ children, theme = defaultTheme }) => (
  <MuiThemeProvider theme={defaultTheme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

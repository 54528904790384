import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialog';
import IsFirstLoginDialog from 'components/IsFirstLoginDialog';
import { LocationsContextProvider } from 'contexts/locations';
import { ThemeProvider } from 'lib/mui';
import { NotistackProvider } from 'lib/notistack';
import { ReactQueryProvider } from 'lib/react-query';
import App from './main';

const root = createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider>
    <NotistackProvider>
      <ReactQueryProvider>
        <BrowserRouter>
          <HelmetProvider>
            <IsFirstLoginDialog />
            <LocationsContextProvider>
              <ConfirmDialog />
              <App />
            </LocationsContextProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ReactQueryProvider>
    </NotistackProvider>
  </ThemeProvider>
);

import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const isProduction = process.env.NODE_ENV === 'production';

export let client;

export const ReactQueryProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: isProduction,
            retry: isProduction ? 2 : 0,
            onError: error => {
              const errorMessage = error.response?.data?.message;
              enqueueSnackbar(errorMessage ?? error.message, { variant: 'error' });
            },
          },
          mutations: {
            onError: error => {
              const errorMessage = error.response?.data?.message;
              enqueueSnackbar(errorMessage ?? error.message, { variant: 'error' });
            },
          },
        },
      }),
    [enqueueSnackbar]
  );

  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import logoSvg from 'assets/logo.svg';
import turnBerrySvg from 'assets/turnberry_2.png';

const TurnBerryHeader = () => {
  return (
    <Box
      sx={{
        width: '100',
        height: '270px',
        display: 'flex',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src={turnBerrySvg} alt="turnberry" style={{ width: '60%' }} />
        </Box>
        <Box
          sx={{
            minHeight: '60px',
            backgroundColor: '#fe0601',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '40px',
          }}
        >
          <Typography variant="h2" sx={{ color: '#fffc', fontWeight: '300' }}>
            To apply for free gas please complete all questions.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          minWidth: '450px',
          backgroundColor: '#000066',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={logoSvg} alt="logo_ezfill" style={{ with: '70px', height: '70px' }} />
      </Box>
    </Box>
  );
};

export { TurnBerryHeader };

import { lazy } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
// import StoreIcon from '@mui/icons-material/Store';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PlaceIcon from '@mui/icons-material/Place';
import People from '@mui/icons-material/People';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Navigate, useRoutes } from 'react-router-dom';
import { Layout } from 'components/Layout';
import { withHelmet } from 'lib/react-helmet';
import { suspense } from 'lib/react-router-dom';
import { useUserContext } from 'hooks/use-user-context';
import { Description } from '@mui/icons-material';
import OnboardingLayout from 'components/Layout/OnboardingLayout';

const SignContract = lazy(() => import('pages/SignContract'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const FuelingReports = lazy(() => import('pages/Reports/FuelingReports'));
const Assets = lazy(() => import('pages/Assets'));
const Orders = lazy(() => import('pages/Orders'));
const StoreOrders = lazy(() => import('pages/StoreOrders'));
const Locations = lazy(() => import('pages/Locations'));
const Customers = lazy(() => import('pages/Customers'));
const Invoices = lazy(() => import('pages/Invoices'));
const Payments = lazy(() => import('pages/Payments'));
const Payment = lazy(() => import('pages/Payment'));
const Billing = lazy(() => import('pages/Billing'));
const ProfileDetails = lazy(() => import('pages/ProfileDetails'));
const Help = lazy(() => import('pages/Help'));
const ConfirmEmailChange = lazy(() => import('pages/ConfirmEmailChange'));

const authenticatedRoutes = [
  {
    path: '/',
    element: suspense(Dashboard),
    name: 'Dashboard',
    requiresOnboarding: true,
  },
  {
    path: '/assets',
    element: suspense(Assets),
    name: 'Assets',
  },
  {
    path: '/orders',
    element: suspense(Orders),
    name: 'Fuel Orders',
    requiresOnboarding: true,
  },
  {
    path: '/storeOrders',
    element: suspense(StoreOrders),
    name: 'Store Orders',
    requiresOnboarding: true,
  },
  {
    path: '/locations',
    element: suspense(Locations),
    name: 'Locations',
  },
  {
    path: '/users',
    element: suspense(Customers),
    name: 'Users',
  },
  {
    path: '/invoices',
    element: suspense(Invoices),
    name: 'Invoices',
    requiresOnboarding: true,
  },
  {
    path: '/payments',
    element: suspense(Payments),
    name: 'Payments',
    requiresOnboarding: true,
  },
  {
    path: '/payment',
    element: suspense(Payment),
    requiresOnboarding: true,
  },
  {
    path: '/billing',
    element: suspense(Billing),
    name: 'Billing',
  },
  {
    path: '/fuelingReports',
    element: suspense(FuelingReports),
    requiresOnboarding: true,
  },
  {
    path: '/profile-details',
    element: suspense(ProfileDetails),
  },
  {
    path: '/confirm-email-change/:id/:token',
    element: suspense(ConfirmEmailChange),
    name: 'Confirm Email Change',
  },
  {
    path: '/help',
    element: suspense(Help),
    name: 'Help',
  },
  {
    path: '/signContract',
    element: suspense(SignContract),
    name: 'Sign Contract',
  },
  { path: '*', element: <Navigate to="/" replace={true} /> },
];

export const AuthenticatedRoutes = () => {
  const {
    userProfile: { permissions, fleetCompany },
  } = useUserContext();

  let routes = authenticatedRoutes.filter(
    r => !!r && (fleetCompany.onboardingStatus === 'completed' || !r.requiresOnboarding)
  );
  if (fleetCompany.onboardingStatus !== 'completed');
  routes[routes.length - 1].element = <Navigate to="/locations" replace={true} />;

  let navigations = [
    {
      path: '/',
      name: 'Dashboard',
      icon: <DashboardIcon />,
      requiresOnboarding: true,
    },
    {
      path: '/assets',
      name: 'Assets',
      icon: <DirectionsCarIcon />,
    },
    {
      path: '/orders',
      name: 'Fuel Orders',
      icon: <ArticleIcon />,
      requiresOnboarding: true,
    },
    // { // saitama disabled.
    //   path: '/storeOrders',
    //   name: 'Store',
    //   icon: <StoreIcon />,
    //   requiresOnboarding: true,
    // },
    {
      path: '/locations',
      name: 'Locations',
      icon: <PlaceIcon />,
    },
    permissions?.userManagement && {
      path: '/users',
      name: 'Users',
      icon: <People />,
    },
    permissions?.billing && {
      path: '/invoices',
      name: 'Invoices',
      icon: <ReceiptLongIcon />,
      requiresOnboarding: true,
    },
    permissions?.billing && {
      path: '/payments',
      name: 'Payments',
      icon: <PaymentsIcon />,
      requiresOnboarding: true,
    },
    permissions?.billing && {
      path: '/billing',
      name: 'Billing',
      icon: <AccountBalanceIcon />,
    },
    {
      name: 'Reports',
      icon: <BarChartIcon />,
      children: [
        {
          name: 'Assets Serviced',
          path: '/fuelingReports',
        },
      ],
      requiresOnboarding: true,
    },
  ].filter(r => !!r && (fleetCompany.onboardingStatus === 'completed' || !r.requiresOnboarding));

  switch (fleetCompany.onboardingStatus) {
    case 'awaiting_compliance':
      break;
    case 'awaiting_signature':
      routes = [
        {
          path: '/profile-details',
          element: suspense(ProfileDetails),
        },
        {
          path: '/confirm-email-change/:id/:token',
          element: suspense(ConfirmEmailChange),
          name: 'Confirm Email Change',
        },
        {
          path: '/help',
          element: suspense(Help),
          name: 'Help',
        },
        {
          path: '/signContract',
          element: suspense(SignContract),
          name: 'Sign Contract',
        },
        { path: '*', element: <Navigate to="/signContract" replace={true} /> },
      ];
      navigations = [
        {
          path: '/signContract',
          name: 'Contract',
          icon: <Description />,
        },
      ];
      break;
    case 'pending_approval':
    case 'completed':
      break;
    default:
      break;
  }

  const element = useRoutes(
    routes.map(route => ({
      path: route.path,
      element: withHelmet(route.element, { title: route.name }),
    }))
  );
  if (fleetCompany.onboardingStatus !== 'completed')
    return <OnboardingLayout routes={routes}>{element}</OnboardingLayout>;

  return (
    <Layout links={navigations} routes={routes}>
      {element}
    </Layout>
  );
};

import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { Box, Stepper, StepButton, Step, Button, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserContext } from 'hooks/use-user-context';

export const OnboardingStepper = () => {
  const {
    userProfile: { fleetCompany },
  } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const links = [
    { path: '/locations', label: 'Locations' },
    { path: '/assets', label: 'Assets' },
    { path: '/billing', label: 'Billing' },
  ];
  const activeStep = links.findIndex(f => f.path === location.pathname);

  if (fleetCompany.onboardingStatus === 'awaiting_signature') return <></>;
  return (
    <Paper sx={{ padding: 1, marginTop: 2, marginBottom: 1 }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          marginTop: 3,
          marginBottom: 1,
          '.MuiStepLabel-labelContainer span': {
            fontSize: 18,
          },
        }}
      >
        {links.map(l => (
          <Step key={l.label} completed={false}>
            <StepButton
              color="inherit"
              onClick={() => {
                navigate(l.path);
              }}
            >
              {l.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button disabled={activeStep <= 0} onClick={() => navigate(links[activeStep - 1].path)}>
          <ArrowBack sx={{ fontSize: 18, marginRight: '4px' }} />
          Back
        </Button>
        <Button
          disabled={activeStep === links.length - 1}
          onClick={() => navigate(links[activeStep + 1].path)}
        >
          Next
          <ArrowForward sx={{ fontSize: 18, marginLeft: '4px' }} />
        </Button>
      </Box>
    </Paper>
  );
};

export default OnboardingStepper;

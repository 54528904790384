import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { withHelmet } from 'lib/react-helmet';
import { suspense } from 'lib/react-router-dom';
import { MiamiDade } from 'pages/MiamiDade';
import { TurnBerry } from 'pages/Turnberry';

const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const RequestPasswordChange = lazy(() => import('pages/ForgotPassword/RequestPasswordChange'));
const ConfirmSecurityCode = lazy(() => import('pages/ForgotPassword/ConfirmSecurityCode'));
const ChangePassword = lazy(() => import('pages/ForgotPassword/ChangePassword'));
const ConfirmEmailChange = lazy(() => import('pages/ConfirmEmailChange'));

const routes = [
  {
    path: '/login',
    element: suspense(Login),
    name: 'Login',
  },
  {
    path: '/createAccount/:nonce',
    element: suspense(SignUp),
    name: 'Sign Up',
  },
  {
    path: '/forgot-password',
    element: suspense(RequestPasswordChange),
    name: 'Forgot Password',
  },
  {
    path: '/forgot-password/:id/security-code',
    element: suspense(ConfirmSecurityCode),
    name: 'Forgot Password',
  },
  {
    path: '/forgot-password/:id/change-password',
    element: suspense(ChangePassword),
    name: 'Forgot Password',
  },
  {
    path: '/confirm-email-change/:id/:token',
    element: suspense(ConfirmEmailChange),
    name: 'Confirm Email Change',
  },
  {
    path: '/turnberry',
    element: suspense(TurnBerry),
    name: 'TurnBerry',
  },
  {
    path: '/miamidade',
    element: suspense(MiamiDade),
    name: 'MiamiDade',
  },

  { path: '*', element: <Navigate to="/login" replace={true} /> },
];

export const UnauthenticatedRoutes = () => {
  const element = useRoutes(
    routes.map(route => ({
      path: route.path,
      element: withHelmet(route.element, { title: route.name }),
    }))
  );

  return element;
};

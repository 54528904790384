import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import LocationField from 'components/LocationField';
import { useMiamiDadeMutation } from 'hooks/use-miamidade-mutation.js';
import { resolve, RHFTextField } from 'lib/react-hook-form';
import promoVideo from '../../assets/EzPromo.mp4';
import promoPicture from '../../assets/miamidade_promo.jpg';
import { MiamiDadeHeader } from './components/Header';

require('./style.css');

const MiamiDade = () => {
  const [fuel, setFuel] = useState('87');
  const [address, setAddress] = useState();
  const { isLoading, mutate } = useMiamiDadeMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('990'));
  const isSM = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { control, handleSubmit, reset } = useForm({
    resolver: resolve(yup =>
      yup.object({
        first_name: yup.string().required('This field is required'),
        last_name: yup.string().required('This field is required'),
        phone: yup
          .string()
          .required('This field is required')
          .matches(/^[0-9]+$/, 'Must be only digits'),
        email: yup.string().email('Must be a valid email').required('This field is required'),
        password: yup
          .string()
          .required('This field is required')
          .matches(/.{8,}/gi, 'Password must have at least 8 characters')
          .matches(/(?=.*[a-z])/gi, 'Password must have at least 1 lowercase character')
          .matches(/(?=.*[A-Z])/gi, 'Password must have at least 1 uppercase character')
          .matches(/(?=.*[0-9])/gi, 'Password must have at least 1 numeric character')
          .matches(/(?=.*[!@#$%^&*])/gi, 'Password must have at least 1 special character'),
        make: yup.string().required('This field is required'),
        model: yup.string().required('This field is required'),
        color: yup.string().required('This field is required'),
      })
    ),
  });

  const onSubmit = asset => {
    mutate(
      {
        asset: {
          ...asset,
          fuel: String(fuel),
          latitude: address?.latitude,
          longitude: address?.longitude,
        },
      },
      {
        onSuccess: () => {
          reset();
          enqueueSnackbar('Success', { variant: 'success' });
          setAddress();
        },
      }
    );
  };

  const flexBoxProps = isMobile
    ? { flexDirection: 'column', gap: 3, marginTop: 3, boxSizing: 'border-box' }
    : { gap: '40px', marginTop: 5 };
  const form_props = isMobile
    ? { boxSizing: 'border-box', marginTop: '50px' }
    : { marginTop: '20px' };
  const divider_props = isMobile ? { marginTop: 5, marginBottom: '32px' } : { marginTop: 10 };

  const TransparentBox = () => {
    return !isMobile ? <Box sx={{ minWidth: '350px' }} /> : null;
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MiamiDadeHeader />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            ...form_props,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...flexBoxProps,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: '300',
                minWidth: '350px',
                margin: 0,
              }}
            >
              Personal Info:
            </Typography>
            <TransparentBox />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...flexBoxProps,
            }}
          >
            <RHFTextField
              label={'First Name'}
              name="first_name"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
            <RHFTextField
              label={'Last Name'}
              name="last_name"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...flexBoxProps,
            }}
          >
            <RHFTextField
              label={'Phone Number'}
              name="phone"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
            <RHFTextField
              label={'E-Mail'}
              name="email"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...flexBoxProps,
            }}
          >
            <RHFTextField
              label={'Password'}
              type="password"
              name="password"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
            <div className="address-location" style={{ minWidth: '350px' }}>
              <LocationField value={address} onChange={setAddress} />
            </div>
          </Box>
          <Box sx={{ ...divider_props, padding: '0px 60px' }}>
            <Divider sx={{ borderStyle: 'solid', borderColor: '#F75010' }} />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...flexBoxProps,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: '300',
                minWidth: '350px',
                margin: 0,
              }}
            >
              Vehicle Info:
            </Typography>
            <TransparentBox />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              ...flexBoxProps,
            }}
          >
            <RHFTextField
              label={'Make'}
              name="make"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
            <TransparentBox />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              ...flexBoxProps,
            }}
          >
            <RHFTextField
              label={'Model'}
              name="model"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
            <TransparentBox />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              ...flexBoxProps,
            }}
          >
            <RHFTextField
              label={'Color'}
              name="color"
              autoComplete="off"
              variant="standard"
              control={control}
              sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
              InputLabelProps={{ style: { color: '#28285C' } }}
            />
            <TransparentBox />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              ...flexBoxProps,
            }}
          >
            <RadioGroup
              defaultValue={'87'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: '350px',
              }}
              onChange={({ target }) => setFuel(target.value)}
            >
              <Typography sx={{ fontWeight: '400', marginRight: '20px', fontSize: '17px' }}>
                Preferred fuel type:{' '}
              </Typography>
              <FormControlLabel value={'87'} control={<Radio size="small" />} label="87" />
              <FormControlLabel value={'93'} control={<Radio size="small" />} label="93" />
            </RadioGroup>
            <TransparentBox />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              ...flexBoxProps,
            }}
          >
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              color={'primary'}
              sx={{ width: '350px' }}
            >
              <Typography variant="button" fontWeight={700}>
                Send
              </Typography>
            </Button>
            <TransparentBox />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
              ...flexBoxProps,
            }}
          >
            <Typography sx={{ fontWeight: '400', fontSize: '14px', maxWidth: '350px' }}>
              Please note: In order to fulfill ones order, the order will need to be placed prior to
              11:59PM the day before the scheduled fill.
            </Typography>
            <Box sx={{ minWidth: '350px' }}></Box>
          </Box>
        </Box>

        <Box
          className="miamidade-promo"
          sx={{
            height: 'auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
            flexDirection: 'column',
            maxWidth: '600px',
            minWidth: '600px',
            margin: 0,
            padding: 0,
          }}
        >
          <img
            alt="Miamidade Promo"
            src={promoPicture}
            width="100%"
            hieght="auto"
            style={{ objectFit: 'contain' }}
          />
          <video
            alt="Miamidade Promo"
            src={promoVideo}
            autoPlay
            loop
            muted
            width="100%"
            hieght="auto"
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          maxHeight: '300px',
          backgroundColor: '#000066',
          padding: '60px 15px 60px 15px',
        }}
      >
        <Box
          sx={{
            margin: 'auto',
            width: '100%',
            maxWidth: '1200px',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'space-around',
            alignItems: 'center',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px',
          }}
        >
          {isSM ? (
            <Box className="residential-use">
              <span style={{}}>For residential use only</span>
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                width: 'auto',
                boxSizing: 'border-box',
                minWidth: isMobile ? 'none' : '400px',
              }}
              color="#fff"
            >
              For residential use only
            </Typography>
          )}
          <Box
            sx={{
              margin: isMobile ? 'auto' : 0,
              display: 'flex',
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Link
              href="https://ezfl.com"
              target="_blank"
              sx={{
                width: isMobile ? '40%' : 'auto',
                minWidth: isMobile ? '200px' : 'none',
                textAlign: 'center',
                fontSize: '16px',
                padding: '2px 10px',
                fontWeight: 'bold',
                textDecorationColor: 'currentcolor',
                color: 'rgba(255, 255, 255, 0.884)',
                ':hover': {
                  color: '#AC380B',
                },
              }}
            >
              Go to EzFill Homepage
            </Link>
            <Link
              href="https://ezfl.com/faqs"
              target="_blank"
              sx={{
                width: isMobile ? '40%' : 'auto',
                minWidth: isMobile ? '200px' : 'none',
                textAlign: 'center',
                fontSize: '16px',
                padding: '2px 10px',
                fontWeight: 'bold',
                color: 'rgba(255, 255, 255, 0.884)',
                textDecorationColor: 'currentcolor',
                ':hover': {
                  color: '#AC380B',
                },
              }}
            >
              FAQs
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { MiamiDade };

import { useState } from 'react';
import { Person, KeyboardArrowDown, Logout, Settings } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'hooks/use-user-context';
import { storage } from 'lib/dom';

export const UserMenu = ({ handleClose }) => {
  const navigate = useNavigate();

  const client = useQueryClient();

  const { userProfile: data } = useUserContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const onButtonClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const onProfileDetails = () => {
    navigate('/profile-details');
    setAnchorEl(null);
    handleClose();
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    storage.remove('access_token');
    storage.remove('user_id');
    client.setQueryData(['user'], null);
    client.clear();

    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <div>
      <Button
        sx={{
          borderRadius: 2,
          textTransform: 'none',
          whiteSpace: 'nowrap',
          bgcolor: 'white',
          color: 'primary.main',
          '&:hover': {
            bgcolor: 'white',
          },
        }}
        id="user-button"
        aria-controls={isOpen && 'user-menu'}
        aria-haspopup="true"
        aria-expanded={isOpen && 'true'}
        variant="contained"
        disableRipple={true}
        fullWidth={true}
        onClick={onButtonClick}
        startIcon={<Person color="accent" />}
        endIcon={<KeyboardArrowDown color="primary" />}
      >
        {data?.firstName}
      </Button>
      <Menu
        sx={{ zIndex: 2001, top: 2 }}
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{ 'aria-labelledby': 'user-button' }}
      >
        <MenuItem onClick={onProfileDetails}>
          <Settings color="accent" style={{ marginRight: '5px' }} />
          Profile Details
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <Logout color="accent" style={{ marginRight: '5px' }} />
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
};

import { Box, Typography, useMediaQuery } from '@mui/material';
import logoSvg from 'assets/logo.svg';
import miamiDadeSvg from 'assets/miami-dade.png';

const MiamiDadeHeader = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));
  const is2K = useMediaQuery(theme => theme.breakpoints.up('2000'));
  const minHeaderHeight = useMediaQuery(theme => theme.breakpoints.up('1390'));

  const logo_props = isTablet
    ? { width: isMobile ? '50%' : '40%' }
    : { width: is2K ? '10%' : '28%' };

  return (
    <Box
      sx={{
        width: '100%',
        height: '270px',
        display: 'flex',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <img
            className="miamidade-img"
            src={miamiDadeSvg}
            alt="MiamiDade"
            style={{ ...logo_props }}
          />
        </Box>
        <Box
          sx={{
            minHeight: '60px',
            backgroundColor: '#ff4000',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#fffc',
              fontWeight: '300',
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: isMobile ? '16px' : 'auto',
            }}
          >
            Become a new EzFill Unlimited Member.
          </Typography>
        </Box>
      </Box>
      {!isTablet && (
        <Box
          sx={{
            minWidth: minHeaderHeight ? '600px' : '400px',
            backgroundColor: '#000066',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={logoSvg} alt="logo_ezfill" style={{ with: '70px', height: '70px' }} />
        </Box>
      )}
    </Box>
  );
};

export { MiamiDadeHeader };

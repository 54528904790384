import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, TextField } from '@mui/material';
import { Select } from '@mui/material';
import { useController } from 'react-hook-form';
import * as yup from 'yup';

/**
 * @param {import('@mui/material').TextFieldProps & import('react-hook-form').UseControllerProps} props
 *
 * @example
 * const { control } = useForm({ defaultValues: { example: '' } });
 * ...
 * <RHFTextField control={control} name="example" />
 * };
 */
export const RHFTextField = ({ control, defaultValue = '', helperText, name, rules, ...props }) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ control, defaultValue, name, rules });

  return (
    <TextField
      {...props}
      {...field}
      inputRef={ref}
      error={Boolean(error)}
      helperText={error?.message || helperText}
      autoComplete="off"
    />
  );
};

export const RHFSelect = ({ control, defaultValue = '', helperText, name, rules, ...props }) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ control, defaultValue, name, rules });

  return (
    <>
      <Select {...props} {...field} inputRef={ref} error={Boolean(error)} />
      <FormHelperText
        sx={{
          color: '#d32f2f',
        }}
      >
        {error?.message}
      </FormHelperText>
    </>
  );
};

/**
 * @param {(builder: typeof yup) => yup} fn
 *
 * @example
 * const { ... } = useForm({
 *   defaultValues: { example: '' },
 *   resolver: resolve(yup =>
 *     yup.object({
 *       example: yup.string().required(),
 *     })
 *   ),
 * });
 */
export const resolve = fn => yupResolver(fn(yup));

import { useState } from 'react';
import { Menu, Close, Help } from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  AppBar,
  Box,
  Container,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ErrorBoundary } from 'react-error-boundary';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoSvg from 'assets/logo_white.png';
import tanker from 'assets/nav-tanker.png';
import { LocationsSelect } from 'components/LocationsSelect';
import { UserMenu } from 'components/UserMenu';

const drawerWidth = 198;
const WITHOUT_SELECT_PATHS = [
  '/profile-details',
  '/users',
  '/locations',
  '/help',
  '/payment',
  '/billing',
  '/signContract',
];
const ALL_LOCATIONS_SUPPORTED = ['/', '/orders', '/invoices', '/payments'];

export const Layout = ({ links, routes, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const minHeaderHeight = useMediaQuery(theme => theme.breakpoints.up('2200'));

  const path = window.location.pathname;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsedItem, setCollapsedItem] = useState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExpand = (link, force) => {
    if (!force && link.name === collapsedItem) {
      setCollapsedItem(null);
    } else {
      setCollapsedItem(link.name);
    }
  };

  const nestedFind = pred => xs =>
    xs.reduce(
      (res, x) => (res ? res : pred(x) ? x?.name : nestedFind(pred)(x.children || [])),
      undefined
    );

  const findByPath = pathname => nestedFind(({ path }) => path === pathname);

  const title = findByPath(location.pathname)(routes);

  const renderMenu = links => {
    return links.map((link, index) => {
      if (link.children && !link.path) {
        return (
          <div key={index}>
            <ListItem disablePadding>
              <ListItemButton
                disableRipple
                onClick={() => handleExpand(link)}
                sx={{
                  borderLeft: '4px solid',
                  borderColor: 'transparent',
                  display: 'flex',
                  paddingLeft: '0px',
                }}
              >
                <ListItemIcon
                  sx={{
                    margin: '0px 10px',
                    minWidth: '24px',
                    color: '#6E7596',
                  }}
                >
                  {link.icon ?? ''}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: 'secondary.main',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                  }}
                  primary={link.name}
                />
                {collapsedItem === link.name ? (
                  <ExpandLess sx={{ color: '#6E7596' }} />
                ) : (
                  <ExpandMore sx={{ color: '#6E7596' }} />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse in={collapsedItem === link.name} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenu(link.children)}
              </List>
            </Collapse>
          </div>
        );
      }
      const isActive = location.pathname === link.path;
      return (
        <Link key={link.path} to={link.path} style={{ textDecoration: 'none' }}>
          <ListItem disablePadding>
            <ListItemButton
              disableRipple
              sx={{
                borderLeft: '4px solid',
                borderColor: isActive ? 'primary.main' : 'transparent',
                display: 'flex',
                paddingLeft: '0px',
              }}
              onClick={isMobile ? handleDrawerToggle : null}
            >
              <ListItemIcon
                sx={{
                  color: isActive ? 'primary.main' : 'secondary.main',
                  margin: '0 10px',
                  minWidth: '24px',
                }}
              >
                {link.icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: isActive ? 'white' : 'secondary.main',
                  textTransform: 'uppercase',
                  fontWeight: 700,
                }}
                primary={link.name}
              />
            </ListItemButton>
          </ListItem>
        </Link>
      );
    });
  };

  const drawer = (
    <>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: ' center',
          alignItems: 'center',
          margin: '34px 0',
          minHeight: '30px',
        }}
      >
        <img src={logoSvg} alt="ezfill-logo" width={140} />
        <Close
          onClick={handleDrawerToggle}
          sx={{
            cursor: 'pointer',
            color: 'white',
            width: '24px',
            display: { sm: 'none' },
            position: 'absolute',
            right: '24px',
          }}
        />
      </Toolbar>
      <Box
        sx={{
          height: 50,
          backgroundColor: '#00003D',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ color: 'white', fontWeight: 700, fontSize: 20 }}>Fleet</Typography>
      </Box>
      <List>{renderMenu(links)}</List>
      <Box sx={{ px: { xs: '20vw', sm: 2 }, marginTop: '30px' }}>
        <UserMenu handleClose={handleDrawerToggle} />
      </Box>
      <Box sx={{ px: { xs: '20vw', sm: 2 } }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              disableRipple
              sx={{
                display: 'flex',
                borderLeft: '4px solid',
                borderColor: 'transparent',
                paddingLeft: '0px',
                marginTop: '5px',
                color: '#6E7596',
              }}
              onClick={() => navigate('/help')}
            >
              <Help style={{ marginRight: '10px', color: '#6E7596' }} />
              <Typography>Need Help?</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: 'none' },
          backgroundColor: '#000066',
          borderRadius: 0,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, position: 'absolute' }}
          >
            <Menu />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              margin: '15px 0',
            }}
          >
            <img src={logoSvg} alt="ezfill-logo" width={140} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={window.document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              backgroundColor: '#000066',
              borderRadius: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#000066',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1300,
            display: minHeaderHeight ? 'block' : 'none',
          }}
        >
          <img src={tanker} alt="ezfill-tanker" style={{ maxWidth: '250px' }} />
        </Box>
      </Box>
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: { xs: 10, sm: 0 },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          sx={{ display: { xs: 'flex' }, flexWrap: 'wrap' }}
        >
          <Typography variant="h1">{title}</Typography>
          {!WITHOUT_SELECT_PATHS.includes(path) && (
            <LocationsSelect
              supportAllLocation={ALL_LOCATIONS_SUPPORTED.includes(path)}
              allLocationByDefault={ALL_LOCATIONS_SUPPORTED.includes(path)}
            />
          )}
        </Stack>
        <ErrorBoundary
          fallback={<Typography color="error">Something went wrong</Typography>} // Temporary error fallback
        >
          {children}
        </ErrorBoundary>
      </Container>
    </Box>
  );
};

export default Layout;

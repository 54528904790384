const ItemLabelLocation = ({ location }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div>{location.name}</div>
    <div
      style={{
        color: '#888',
        letterSpacing: 0.5,
        fontSize: 12,
        lineHeight: 1,
      }}
    >
      {location.address.line1}
    </div>
  </div>
);
export default ItemLabelLocation;

const prefix = 'EzFill:';

export const storage = {
  set: (key, value) => {
    const serializedValue = typeof value !== 'string' ? JSON.stringify(value) : value;

    localStorage.setItem(prefix + key, serializedValue);
  },

  get: key => {
    const serializedValue = localStorage.getItem(prefix + key);

    try {
      return JSON.parse(serializedValue);
    } catch {
      return serializedValue;
    }
  },

  remove: key => {
    localStorage.removeItem(prefix + key);
  },
};

import { ArrowDropDown } from '@mui/icons-material';
import { InputLabel, Select, Box } from '@mui/material';

const CustomSelect = ({ label, children, customStyles, fullWidth, ...props }) => {
  return (
    <>
      <InputLabel
        sx={{
          paddingLeft: '5px',
          fontWeight: '400',
          color: '#28285C',
          paddingBottom: '10px',
          width: fullWidth ? '100%' : 'auto',
        }}
        id="select-component"
      >
        {label}
      </InputLabel>
      <Select
        id="select-component"
        sx={{
          height: 50,
          width: fullWidth ? '100%' : 'auto',
          fontFamily: 'Montserrat',
          fontWeight: 600,
          fontSize: 18,
          backgroundColor: 'white',
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: 2,
          '&::before, &::after': {
            display: 'none',
          },

          '& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input': {
            pr: 6,
          },

          '& .MuiSelect-select.MuiSelect-standard:focus': {
            bgcolor: 'transparent',
          },

          '& .MuiSelect-icon': {
            top: 5,
            bottom: 5,
            right: 5,
          },
          ...customStyles,
        }}
        displayEmpty={true}
        IconComponent={DropdownIcon}
        {...props}
      >
        {children}
      </Select>
    </>
  );
};

const DropdownIcon = props => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 32,
        minWidth: 32,
        bgcolor: 'rgba(25, 118, 210, 0.12)',
        borderRadius: 2.5,
      }}
      {...props}
    >
      <ArrowDropDown sx={{ fill: theme => theme.palette.accent.main }} />
    </Box>
  );
};

export default CustomSelect;

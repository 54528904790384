import { useQuery, useQueryClient, useMutation } from 'react-query';
import { ezFillApi } from 'api/ez-fill';
import { storage } from 'lib/dom';
import { useSnackbar } from 'notistack';

export const useInviteUserQuery = () => {
  const { enqueueSnackbar } = useSnackbar();

  const client = useQueryClient();

  return useMutation(
    async signupData => {
      const response = await ezFillApi.post(`/fleetportal/invite`, signupData);
      return response.data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Invitation Sent', { variant: 'success' });
        return client.invalidateQueries(['user']);
      },
      onError: error => {
        enqueueSnackbar(error.response?.data?.message || 'Error sending invitation', {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditUserQuery = () => {
  const { enqueueSnackbar } = useSnackbar();

  const client = useQueryClient();

  return useMutation(
    async userData => {
      const response = await ezFillApi.put(`/fleetclients/${userData._id}`, userData);
      return response.data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Updated User Data', { variant: 'success' });
        return client.invalidateQueries(['user']);
      },
    }
  );
};

export const useDeleteUserQuery = () => {
  const { enqueueSnackbar } = useSnackbar();

  const client = useQueryClient();

  return useMutation(
    async customerId => {
      const response = await ezFillApi.delete(`/fleetportal/customer/${customerId}`);
      return response.data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('User deleted successfully', { variant: 'success' });
        return client.invalidateQueries(['user']);
      },
    }
  );
};

export const useUserQuery = () =>
  useQuery(
    ['user'],
    async ({ signal }) => {
      const id = storage.get('user_id');

      const response = await ezFillApi.get(`/fleetclients/${id}/profile`, { signal });
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      onError: null,
    }
  );

export const useUsersQuery = () => {
  return useQuery(
    ['users'],
    async () => {
      const response = await ezFillApi.get(`/fleetportal/findCustomersByCompany`);
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useInvitationsQuery = () => {
  return useQuery(
    ['invitations'],
    async () => {
      const response = await ezFillApi.get(`/fleetclients/invitationsBySender`);
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useResendInvitationQuery = () => {
  const { enqueueSnackbar } = useSnackbar();

  const client = useQueryClient();

  return useMutation(
    async invitationId => {
      const response = await ezFillApi.get(`/fleetclients/resend-invitation/${invitationId}`);
      return response.data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Invitation send successfully', { variant: 'success' });
        return client.invalidateQueries(['invitation']);
      },
      onError: error => {
        enqueueSnackbar(error.response?.data?.message || 'Error sending invitation', {
          variant: 'error',
        });
      },
    }
  );
};

import { useUserContext } from 'hooks/use-user-context';
import { AuthenticatedRoutes } from 'routes/authenticated';
import { UnauthenticatedRoutes } from 'routes/unauthenticated';

export const App = () => {
  const { userProfile } = useUserContext();

  return userProfile ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
};

export default App;

import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { ezFillApi } from 'api/ez-fill';

export const useMiamiDadeMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async ({ asset }) => {
      const response = await ezFillApi.post('/miami-dade/create', {
        ...asset,
      });

      return response.data;
    },
    {
      onSuccess: () =>
        enqueueSnackbar(
          'Thanks for submitting the form. Please check your inbox for further instructions and don’t forget to download the app to order.',
          { variant: 'success' }
        ),
    }
  );
};

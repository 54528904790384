import { createContext, useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { useUserQuery } from 'hooks/use-user-query';
import { useQueryClient } from 'react-query';
import { storage } from 'lib/dom';

export const LocationsContext = createContext();

export const LocationsContextProvider = ({ children }) => {
  const { data: userProfile, isLoading, refetch } = useUserQuery();
  const setSeachParams = useSearchParams().at(1);
  const [locationSelected, setLocation] = useState();
  const [branchSelected, setBranchSelected] = useState();
  const [isAllLocationsSelected, setAllLocationSelected] = useState(false);
  const client = useQueryClient();

  useEffect(() => {
    if (!userProfile) return;
    const currentSubdomain = window.location.host.split('.').shift().split(':').shift();
    switch (currentSubdomain) {
      case 'localhost':
        if (userProfile.subdomain.length > 0)
          window.location.href = window.location.href.replace(
            currentSubdomain,
            `${userProfile.subdomain}.${currentSubdomain}`
          );
        break;
      default:
        if (currentSubdomain !== userProfile.subdomain)
          window.location.href = window.location.href.replace(
            userProfile.subdomain.length ? currentSubdomain : `${currentSubdomain}.`,
            userProfile.subdomain
          );
        break;
    }

    if (userProfile.status === 'inactive' || userProfile?.fleetCompany?.status === 'inactive') {
      storage.remove('access_token');
      storage.remove('user_id');
      client.setQueryData(['user'], null);
      client.clear();
    }

    if (
      userProfile.fleetBranches?.length > 0 &&
      userProfile.fleetBranches[0].branch?.fleetLocations?.length > 0
    ) {
      const newBranchSelected = userProfile.fleetBranches[0];
      setLocationSelected(
        newBranchSelected,
        (newBranchSelected.branchLocation &&
          newBranchSelected.branch.fleetLocations.find(
            location => location.fleetBranch === newBranchSelected.branchLocation
          )) ??
          newBranchSelected.branch.fleetLocations[0]
      );
    }
  }, [userProfile]);

  const setLocationSelected = useCallback(
    (branch, location) => {
      setBranchSelected(branch);
      setLocation(prevValue => {
        //if client is on a page, we reset paginator
        if (prevValue?._id !== location._id) {
          const nextSearchParams = new URLSearchParams(window.location.search);
          nextSearchParams.delete('page');
          nextSearchParams.delete('search');
          nextSearchParams.delete('sortBy');

          setSeachParams(nextSearchParams);
        }

        return location;
      });
    },
    [setSeachParams]
  );

  return (
    <LocationsContext.Provider
      value={{
        isLoading,
        userProfile,
        branchSelected,
        locationSelected,
        setLocationSelected,
        isAllLocationsSelected,
        setAllLocationSelected,
        refetch,
      }}
    >
      {!isLoading ? children : <Loading />}
    </LocationsContext.Provider>
  );
};

import React from 'react';
import { Alert, Button, Checkbox } from '@mui/material';
import { useUserContext } from 'hooks/use-user-context';
import { Info } from '@mui/icons-material';
import { useState } from 'react';
import { ezFillApi } from 'api/ez-fill';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { openConfirm } from 'components/ConfirmDialog';

const OnboardingStatus = () => {
  const {
    userProfile: { fleetCompany },
  } = useUserContext();
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [agreeCheck, setAgreeCheck] = useState(false);

  const handleNotify = () => {
    openConfirm(
      'Account Ready',
      'Are you sure you want to notify EzFill? This action cannot be undone.',
      async () => {
        try {
          await ezFillApi.post(`/fleetportal/companyReady/${fleetCompany._id}`);
          enqueueSnackbar('Notification sent', { variant: 'success' });
          client.invalidateQueries(['user']);
        } catch (error) {
          enqueueSnackbar(
            error.response?.data?.message || `Something went wrong: ${error.message}`,
            {
              variant: 'error',
            }
          );
        }
      }
    );
  };

  switch (fleetCompany.onboardingStatus) {
    case 'awaiting_compliance':
      return (
        <Alert
          icon={<Info fontSize="inherit" htmlColor="#ad7a09" />}
          severity="warning"
          sx={{ bgcolor: '#fcfcd7', color: '#ad7a09', fontWeight: '400' }}
        >
          <div style={{ fontSize: 16 }}>Welcome!</div>
          Please complete your account using the steps below
          <div>
            <Checkbox
              color="primary"
              checked={agreeCheck}
              onChange={() => setAgreeCheck(checked => !checked)}
            />
            <span>I have added the missing information and my account is ready to get fueled</span>
            <div>
              <Button variant="contained" disabled={!agreeCheck} onClick={handleNotify}>
                Notify EzFill
              </Button>
            </div>
          </div>
        </Alert>
      );
    case 'ready_to_sign':
      return (
        <Alert icon={<Info fontSize="inherit" />} severity="info">
          Thanks for completing your account. We will notify you when the contract is ready to be
          signed
        </Alert>
      );
    case 'awaiting_signature':
      return (
        <Alert icon={<Info fontSize="inherit" />} severity="info">
          Your contract is ready!. Please sign below
        </Alert>
      );
    case 'pending_approval':
      return (
        <Alert icon={<Info fontSize="inherit" />} severity="info">
          We are reviewing your signature. You will be notified by email when it is complete
        </Alert>
      );
    default:
      return <></>;
  }
};
export default OnboardingStatus;

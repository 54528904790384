import axios from 'axios';
import { storage } from 'lib/dom';

export const ezFillApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

ezFillApi.interceptors.request.use(request => {
  const token = storage.get('access_token');

  if (token) {
    request.headers.authorization = `Bearer ${token}`;
  }

  return request;
});

ezFillApi.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response?.status === 403 &&
      error.response?.data?.message === 'You are not permitted to perform this action.'
    ) {
      storage.remove('access_token');
      storage.remove('user_id');

      window.location.href = '/login';

      return;
    }

    throw error;
  }
);

import { useState, useImperativeHandle, createRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import helpVideo from 'assets/help_video.mp4';

const localRef = createRef();

export const openIsFirstLoginDialog = () => localRef?.current?.openIsFirstLoginDialog();

const ConfirmDialog = () => {
  const [open, setOpen] = useState(false);

  const openIsFirstLoginDialog = () => {
    setOpen(true);
  };
  useImperativeHandle(localRef, () => ({
    openIsFirstLoginDialog,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-description" fullWidth>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <video
            alt="Help Video"
            src={helpVideo}
            autoPlay
            width="100%"
            hieght="auto"
            style={{ objectFit: 'contain' }}
            controls
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

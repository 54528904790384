import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import footerTanker from 'assets/footer_tanker.png';
import { useTurnBerryMutation } from 'hooks/use-turnberry-mutation';
import { resolve, RHFTextField } from 'lib/react-hook-form';
import { TurnBerryHeader } from './components/Header';

const TurnBerry = () => {
  const [fuel, setFuel] = useState('87');
  const { isLoading, mutate } = useTurnBerryMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } = useForm({
    resolver: resolve(yup =>
      yup.object({
        first_name: yup.string().required('This field is required'),
        last_name: yup.string().required('This field is required'),
        phone: yup
          .string()
          .required('This field is required')
          .matches(/^[0-9]+$/, 'Must be only digits'),
        email: yup.string().email('Must be a valid email').required('This field is required'),
        make: yup.string().required('This field is required'),
        model: yup.string().required('This field is required'),
        color: yup.string().required('This field is required'),
      })
    ),
  });

  const onSubmit = asset => {
    mutate(
      { asset: { ...asset, fuel: String(fuel) } },
      {
        onSuccess: () => {
          reset();
          enqueueSnackbar('Success', { variant: 'success' });
        },
      }
    );
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TurnBerryHeader />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ marginTop: '100px', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
          }}
        >
          <RHFTextField
            label={'First Name'}
            name="first_name"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
          <RHFTextField
            label={'Phone Number'}
            name="phone"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 10,
          }}
        >
          <RHFTextField
            label={'Last Name'}
            name="last_name"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
          <RHFTextField
            label={'E-Mail'}
            name="email"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
        </Box>

        <Box sx={{ marginTop: 10, padding: '0px 60px' }}>
          <Divider sx={{ borderStyle: 'solid', borderColor: '#F75010' }} />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 5,
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontWeight: '300', minWidth: '350px', marginLeft: '-20px' }}
          >
            Vehicle Info:
          </Typography>
          <Box sx={{ minWidth: '350px' }}></Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 5,
            zIndex: 9999,
          }}
        >
          <RHFTextField
            label={'Make'}
            name="make"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
          <Box sx={{ minWidth: '350px' }}></Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 5,
            zIndex: 9999,
          }}
        >
          <RHFTextField
            label={'Model'}
            name="model"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
          <Box sx={{ minWidth: '350px' }}></Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 5,
            zIndex: 9999,
          }}
        >
          <RHFTextField
            label={'Color'}
            name="color"
            autoComplete="off"
            variant="standard"
            control={control}
            sx={{ minWidth: '350px', maxWidth: '350px', padding: 0 }}
            InputLabelProps={{ style: { color: '#28285C' } }}
          />
          <Box sx={{ minWidth: '350px' }}></Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 5,
            zIndex: 9999,
          }}
        >
          <RadioGroup
            defaultValue={'87'}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '350px' }}
            onChange={({ target }) => setFuel(target.value)}
          >
            <Typography sx={{ fontWeight: '400', marginRight: '20px', fontSize: '17px' }}>
              Preferred fuel type:{' '}
            </Typography>
            <FormControlLabel value={'87'} control={<Radio size="small" />} label="87" />
            <FormControlLabel value={'93'} control={<Radio size="small" />} label="93" />
          </RadioGroup>
          <Box sx={{ minWidth: '350px' }}></Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            marginTop: 5,
            zIndex: 9999,
          }}
        >
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color={'primary'}
            sx={{ width: '350px' }}
          >
            <Typography variant="button" fontWeight={700}>
              Send
            </Typography>
          </Button>
          <Box sx={{ minWidth: '350px' }}></Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '-340px',
        }}
      >
        <img src={footerTanker} alt="footer_tanker" style={{ width: '50%', height: '100%' }} />
        {/* <img src={truck} alt="xd" style={{ position: 'absolute', bottom: 0, right: 0 }} />
        <Box
          sx={{
            height: '500px',
            borderRadius: '100% 0 0 0',
            backgroundColor: '#F75010',
            width: '60vw',
          }}
        /> */}
      </Box>
    </Box>
  );
};

export { TurnBerry };

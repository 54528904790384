import React, { useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/Loading';
import { useUserContext } from 'hooks/use-user-context';
import CustomSelect from './components/CustomSelect';
import ItemLabelLocation from './components/ItemLabelLocation';

export const LocationsSelect = ({
  customStyles = {},
  fullWidth = false,
  variant = 'standard',
  supportAllLocation = false,
  allLocationByDefault = false,
}) => {
  const navigate = useNavigate();
  const [isLocationOpen, setLocationOpen] = useState(false);
  const [isBranchOpen, setBranchOpen] = useState(false);
  const [searchBranch, setSearchBranch] = useState('');
  const [searchLocation, setSearchLocation] = useState('');

  const {
    userProfile,
    branchSelected,
    locationSelected,
    setLocationSelected,
    isAllLocationsSelected,
    setAllLocationSelected,
  } = useUserContext();

  useEffect(() => {
    if (supportAllLocation && allLocationByDefault) setAllLocationSelected(true);
    return () => {
      if (supportAllLocation) setAllLocationSelected(false);
    };
  }, [supportAllLocation, allLocationByDefault]);

  const handleLocationOpen = () => {
    setLocationOpen(true);
    setSearchLocation('');
    setSearchBranch('');
  };

  const handleBranchChange = event => {
    const newBranchSelected = userProfile.fleetBranches.find(
      ({ branch }) => branch._id === event.target.value
    );
    if (!newBranchSelected) return;
    const newLocationSelected =
      newBranchSelected.branch.fleetLocations.find(
        location => location.fleetBranch === newBranchSelected.branchLocation
      ) ?? newBranchSelected.branch.fleetLocations[0];
    if (!newLocationSelected) return;

    setLocationSelected(newBranchSelected, newLocationSelected);
  };
  const handleLocationChange = event => {
    const newBranchSelected = userProfile.fleetBranches.find(
      ({ branch }) => !!branch.fleetLocations.find(location => location._id === event.target.value)
    );
    if (!newBranchSelected) return;
    const newLocationSelected = newBranchSelected.branch.fleetLocations.find(
      location => location._id === event.target.value
    );
    if (!newLocationSelected) return;

    setLocationSelected(newBranchSelected, newLocationSelected);
  };

  if (!locationSelected) return <Loading />;

  const fleetBranches = userProfile.fleetBranches.filter(b =>
    searchBranch.length ? b.branch.name.toUpperCase().includes(searchBranch.toUpperCase()) : true
  );
  const fleetLocations = branchSelected.branch.fleetLocations.filter(location =>
    `${location.name}${location.address.line1}`.toUpperCase().includes(searchLocation.toUpperCase())
  );

  return (
    <div style={{ display: 'flex', flexDirection: fullWidth ? 'column' : 'row', flexWrap: 'wrap' }}>
      <div
        style={{
          width: fullWidth ? '100%' : 'auto',
          marginBottom: '15px',
          marginRight: fullWidth ? 0 : '15px',
        }}
      >
        <CustomSelect
          label="Branch"
          open={isBranchOpen}
          onOpen={setBranchOpen.bind(null, true)}
          onClose={setBranchOpen.bind(null, false)}
          variant={variant}
          fullWidth={fullWidth}
          customStyles={customStyles}
          onChange={handleBranchChange}
          value={branchSelected?.branch._id}
          renderValue={v =>
            userProfile.fleetBranches.find(({ branch }) => branch._id === v)?.branch.name
          }
        >
          <MenuItem
            value=""
            style={{ backgroundColor: 'white', padding: 5 }}
            onKeyDown={e => e.stopPropagation()}
            onClickCapture={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <TextField
              inputRef={input => {
                setTimeout(() => {
                  input && input.focus();
                }, 100);
              }}
              fullWidth
              size="small"
              placeholder="Search..."
              onChange={({ target: { value } }) => setSearchBranch(value)}
            />
          </MenuItem>
          {fleetBranches.map(({ branch }) => (
            <MenuItem
              key={branch._id}
              value={branch._id}
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {branch.name}
            </MenuItem>
          ))}
        </CustomSelect>
      </div>
      <div style={{ width: fullWidth ? '100%' : 'auto' }}>
        <CustomSelect
          label="Location"
          open={isLocationOpen}
          onOpen={handleLocationOpen}
          onClose={setLocationOpen.bind(null, false)}
          fullWidth={fullWidth}
          variant={variant}
          customStyles={customStyles}
          onChange={handleLocationChange}
          value={locationSelected?._id}
          renderValue={v => {
            if (supportAllLocation && isAllLocationsSelected)
              return <div style={{ display: 'flex', flexDirection: 'column' }}>All Locations</div>;

            const value = branchSelected.branch.fleetLocations.find(location => location._id === v);
            return <ItemLabelLocation location={value} />;
          }}
        >
          {supportAllLocation ? (
            <MenuItem
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              onClick={e => {
                setAllLocationSelected(true);
                setLocationOpen(false);
              }}
            >
              <b>All Locations</b>
            </MenuItem>
          ) : null}
          <MenuItem
            value=""
            style={{ backgroundColor: 'white', padding: 5 }}
            onKeyDown={e => e.stopPropagation()}
            onClickCapture={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <TextField
              inputRef={input => {
                setTimeout(() => {
                  input && input.focus();
                }, 100);
              }}
              fullWidth
              size="small"
              placeholder="Search..."
              onChange={({ target: { value } }) => setSearchLocation(value)}
            />
          </MenuItem>
          {fleetLocations.map(location => (
            <MenuItem
              key={location._id}
              value={location._id}
              style={{ paddingTop: 10, paddingBottom: 10 }}
              onClick={() => {
                if (supportAllLocation) setAllLocationSelected(false);
              }}
            >
              <ItemLabelLocation location={location} />
            </MenuItem>
          ))}
          {userProfile?.permissions?.orderManagment && (
            <MenuItem
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              onClick={e => {
                setLocationOpen(false);
                navigate(`/locations?open=drawer`);
                e.stopPropagation();
              }}
            >
              <Add />
              <b>Add Location</b>
            </MenuItem>
          )}
        </CustomSelect>
      </div>
    </div>
  );
};

import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = () => (
  <Box
    sx={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 'calc(50% - 20px)',
      margin: 'auto',
      height: '40px',
      width: '40px',
      '& img': {
        position: 'absolute',
        height: '25px',
        width: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      },
    }}
  >
    <CircularProgress />
  </Box>
);

export default Loading;

import { useState, useImperativeHandle, createRef } from 'react';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const localRef = createRef();

export const openConfirm = (title, description, handleSubmit = async () => {}) =>
  localRef?.current?.openConfirm(title, description, handleSubmit);

const ConfirmDialog = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Confirm');
  const [description, setDescription] = useState('Are you sure you want to proceed?');
  const [handleSubmit, setHandleSubmit] = useState(() => () => {});
  const openConfirm = async (title, description, handleSubmit = async () => {}) => {
    setTitle(title);
    setDescription(description);
    setOpen(true);
    setHandleSubmit(() => handleSubmit);
  };
  useImperativeHandle(localRef, () => ({
    openConfirm,
  }));

  const handleClose = () => {
    setOpen(false);
    setHandleSubmit(() => () => {});
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      handleSubmit && (await handleSubmit());
    } catch (e) {}
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} autoFocus disabled={loading}>
          {loading ? <CircularProgress /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

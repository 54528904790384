import { useMutation } from 'react-query';
import { ezFillApi } from 'api/ez-fill';

export const useTurnBerryMutation = () => {
  return useMutation(async ({ asset }) => {
    const response = await ezFillApi.post('/turnberry/create', {
      ...asset,
    });

    return response.data;
  });
};

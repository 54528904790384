import { Box, Container, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import logoSvg from 'assets/logo_blue.png';
import { LocationsSelect } from 'components/LocationsSelect';
import { UserMenu } from 'components/UserMenu';
import OnboardingStatus from 'pages/Dashboard/components/OnboardingStatus';
import OnboardingStepper from './components/OnboardingStepper';

const drawerWidth = 198;
const WITHOUT_SELECT_PATHS = [
  '/',
  '/profile-details',
  '/users',
  '/locations',
  '/help',
  '/payment',
  '/billing',
  '/signContract',
];
const ALL_LOCATIONS_SUPPORTED = ['/', '/orders', '/invoices', '/payments'];

export const OnboardingLayout = ({ routes, children }) => {
  const path = window.location.pathname;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Box
          sx={{
            position: 'fixed',
            top: 20,
            left: 20,
            zIndex: 1300,
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' },
          }}
        >
          <img src={logoSvg} alt="ezfill-tanker" style={{ width: '170px' }} />
          <UserMenu handleClose={() => {}} />
        </Box>
        <Box sx={{ px: { xs: '20vw', sm: 2 }, marginTop: '30px' }}></Box>
      </Box>
      <Container
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <OnboardingStatus />
        <OnboardingStepper />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          mb={1}
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          {!WITHOUT_SELECT_PATHS.includes(path) ? (
            <LocationsSelect
              supportAllLocation={ALL_LOCATIONS_SUPPORTED.includes(path)}
              allLocationByDefault={ALL_LOCATIONS_SUPPORTED.includes(path)}
            />
          ) : null}
        </Stack>
        <ErrorBoundary
          fallback={<Typography color="error">Something went wrong</Typography>} // Temporary error fallback
        >
          {children}
        </ErrorBoundary>
      </Container>
    </Box>
  );
};

export default OnboardingLayout;
